<template>
  <div v-if="lesson !== null">
    <div class="flex flex-wrap justify-between">
      <a-button v-if="user.isAdmin" @click="$router.go(-1)" class="mr-2">
        Regresar
      </a-button>
      <a-button v-else @click="$router.push({ name: 'lessons.index', query: { coursesection: lesson.course_section_id }})" class="mr-2">
        Regresar
      </a-button>
      <a-dropdown width="w-80">
        <template #menu>
          <!--          <a-dropdown-item @click="handleEvaluated">-->
          <a-dropdown-item @click="handleEvaluated()">
            {{ lesson.evaluated_at ? 'esta clase ya ha sido evaluada' : 'EVALUAR esta clase' }}
          </a-dropdown-item>
          <a-dropdown-item @click="setCeroToAll">
            <div class="text-left">
              Asignar CERO a toda la seccion para la fecha
            </div>
          </a-dropdown-item>
        </template>
      </a-dropdown>
    </div>

    <div class="py-4 flex justify-between flex-wrap">
      <h2 v-if="lesson.subject" class="text-2xl">Tema: {{ lesson.subject }}</h2>
      <h2 v-else class="text-2xl">Tema: - sin asignar -</h2>
<!--      <a-input-->
<!--        v-else-->
<!--        label="Tema"-->
<!--        placeholder="Tema"-->
<!--        v-model="subject"/>-->
      <div>
        <h4 v-if="lesson.evaluated" class="text-xl">Fecha: {{ lesson.date | date }}</h4>
        <h4 v-if="lesson.registered_at" class="text-xl">Fecha de registro: {{ lesson.registered_at | date }}</h4>
        <h4 v-else class="text-xl">Fecha de registro: No se ha registrado esta clase</h4>
      </div>
<!--      <div v-else class="flex items-center">-->
<!--        <h4 class="text-xl mr-2">Fecha:</h4>-->
<!--        <a-input-->
<!--          v-model="date"-->
<!--          type="date"-->
<!--          label="Fecha de inicio"/>
 :pagination="pagination" @paginate="search({ page: $event })"-->
<!--      </div>-->
    </div>
    <a-table
      :skeleton-count="5"
      :loading="loadingLesson"
      :columns="columns"
      :source="lesson.students">
      <template #td(name)="{ item }">
          <p :class="['text-black', {
            'text-red-400': includeItem(item)
          }]">{{`${item.last_name} ${item.first_name}`}}</p>
      </template>
      <template #td(note)="{ item }">
<!--          <a-input-->
<!--              :min="0"-->
<!--              :value="item.note"-->
<!--              placeholder="Nota"-->
<!--              v-model="item.note"-->
<!--          />-->
          <a-input
            v-if="lesson.evaluated"
            :id="`student_note_${item.id}`"
            type="number"
            :max="20"
            class="w-fit mb-2 flex-1"
            placeholder="nota"
            v-model="item.note"
            :disabled="item.failed_by_delay"
            :error="error(`${item}.note`, { attribute: 'note' })"/>
      </template>
      <template #td(attendance)="{ item  }">
           <a-switch v-model="item.attendance" :disabled="item.note > 0 && !user.isAdmin">
              <template #suffix>
                Marcar inasistencia
              </template>
           </a-switch>
      </template>
      <template #td(delayed)="{ item  }">
        <a-switch v-model="item.delayed">
          <template #suffix>
            Marcar retraso
          </template>
        </a-switch>
      </template>
    </a-table>



    <!--div class="mt-2 flex flex-wrap">
      <h1 class="text-gray-800 text-2xl text-center py-8" v-if="!lesson.students.length">
        No hay estudiantes registrados
      </h1>
      <div v-for="(student, k) in lesson.students" :key="student.id" class="bg-white w-64 border border-gray-100 rounded-xl p-4 m-1 focus-within:shadow-lg flex flex-col">
        <h1 class="text-xl mb-2">
          {{ student.first_name }} {{ student.last_name }}
        </h1>

        <a-input
          v-if="lesson.evaluated"
          :id="`student_note_${student.id}_${k}`"
          label="Nota"
          type="number"
          :min="0"
          class="w-fit mb-2 flex-1"
          placeholder="nota"
          v-model.number="student.note"
          :error="error(`${k}.note`, { attribute: 'nota' })"/>

        <div class="flex-1 justify-self-end items-end">
          <div class="flex space-x-2 items-center mb-2" >
            <a-switch v-model="student.attendance" :disabled="student.note > 0">
              <template #suffix>
                Marcar inasistencia
              </template>
            </a-switch>
          </div>
          <div class="flex space-x-2 items-center">
            <a-switch v-model="student.delayed">
              <template #suffix>
                Marcar retraso
              </template>
            </a-switch>
          </div>
        </div>
      </div>
    </div-->

    <div class="flex justify-end py-4">
<!--      <a-button @click="bulkUpdate({ lesson_id: lesson.id, students: lesson.students })">-->
<!--        Confirmar-->
<!--      </a-button>-->
      <a-button @click="handleBulk({ lesson_id: lesson.id, students: lesson.students })">
        Confirmar
      </a-button>
    </div>
  </div>
</template>


<script>
import { mapActions, mapState, mapGetters } from 'vuex';
export default {
    data () {
        return {
            subject: '',
            date: '',
            students: []
        }
    },
    computed: {
        ...mapState({
            user: state => state.session.user,
            lesson: state => state.lessons.current,
            loadingLesson: state => state.lessons.loading,
            studentsFailed: state => state.lessons.errors,
        }),
        ...mapGetters({
            error: 'error'
        }),
        columns() {
            const auxColumn = []
            auxColumn.push({ title: 'Nombre', slot: 'name', display: true },)
            if (this.lesson.evaluated) {
                auxColumn.push({ title: 'Nota', slot: 'note', display: true })
            }
            auxColumn.push(
                { title: 'Asistencia', slot: 'attendance', display: true },
                { title: 'retraso', slot: 'delayed', display: true }
            )
            return auxColumn.filter($0 => $0.display)
        },
        mappedFailedStudents() {
          if (this.studentsFailed) {
            // this.studentsFailed.forEach(($0) => {
            //   console.log($0)
            // })
            const formattedStudents = JSON.parse(JSON.stringify(this.studentsFailed))
            const formattedStudentsArray = Object.values(formattedStudents)

            return formattedStudentsArray.map(($0) => $0.student_id)
            // formattedStudents.forEach($0 => arr.push($0.student_id))
            // console.log(JSON.parse(JSON.stringify(this.studentsFailed)).map($0 => $0.student_id))
            // JSON.parse(JSON.stringify(obj))
            // return this.studentsFailed.map($0 => {
            //   console.log($0)
            //   return $0.student_id
            // })
          }
          // console.log(test)
          return []
        }
    },
    methods: {
        ...mapActions({
            fetchLesson: 'lessons/show',
            bulkUpdate: 'lessons/bulkUpdate',
            update: 'lessons/update'
        }),
        includeItem(item){
          // return this.mappedFailedStudents.length > 0 ? this.mappedFailedStudents.includes(item.id) : false
          return item.failed_by_delay
        },
        handleBulk(values) {
            // this.handleBulk2(values)
            const mappedNotes = values.students.filter(($0, i) => {
                // console.log($0.note === '' ? 'null' : '0')
                //   delete $0.first_name
                //   delete $0.last_name
                if (JSON.stringify($0) !== JSON.stringify(this.students[i])) {
                    if ($0.note === this.students[i].note) {
                        delete $0.note
                    } else if($0.note === "") {
                        console.log('is empty')
                        $0.note = null
                    }
                    return $0
                }
            })
            this.bulkUpdate({ lesson_id: values.lesson_id, students: mappedNotes })
              .then(() => {
                this.fetchLesson({
                  ...this.$route.params,
                  filters: {
                    limit: 0,
                    with: 'absencesByDelay,absencesByAttendance,students,courseSection.course'
                  }
                })
              }
            )
        },
        // handleBulk2(values) {
        //     const mappedNotes = values.students.filter(($0) => {
        //       // console.log($0.note === '' ? 'null' : '0')
        //       //   delete $0.first_name
        //       //   delete $0.last_name
        //         if (!$0.note) {
        //           delete $0.note
        //         }
        //         return $0
        //     })
        //     console.log(mappedNotes)
        //     this.bulkUpdate({ lesson_id: values.lesson_id, students: mappedNotes })
        // },
        mapAbsences(student = null) {
            let string = ''

            const haveOneUnattendance = this.lesson.absences_by_attendance.some($0 => $0.student_id === student.id)
            const haveOneDelay = this.lesson.absences_by_delay.some($0 => $0.student_id === student.id)

            if (haveOneUnattendance) {
                string += `Tiene una inasistencia`
            }

            if (haveOneDelay) {
                string += `${string.length > 0 ? ' y' : 'Tiene'} un retraso`
            }

            return string
        },
        handleEvaluated() {
          // this.lesson.subject = this.subject
          if(!this.lesson.evaluated_at) {
            this.$repository.lessons
              .evaluate(this.$route.params.id, true)
              .then(() => {
                this.$message.success({
                  message: `Se ha evaluado la clase con exito`,
                  posdataon: `top-right`
                })
                this.fetchLesson({
                  ...this.$route.params,
                  filters: {
                    limit: 0,
                    with: 'absencesByDelay,absencesByAttendance,students,courseSection.course'
                  }
                })
              })
          }
        },
        setCeroToAll() {
            this.lesson.students = this.lesson.students.map(it => ({
                ...it,
                note: 0
            }))
        },
        fixNote(student) {
          this.lesson.students = this.lesson.students.map(it => {
            if (it.id === student.id) {
              // if (it.attendance || it.note === "") {
              return { ...it, note: 0 }
              // }
            } else {
              return it
            }
          })
          //
          // this.lesson.students.find((it) => {
          //
          // })
        }
    },
    mounted() {
      this.fetchLesson({
            ...this.$route.params,
            filters: {
                limit: 0,
                with: 'absencesByDelay,absencesByAttendance,courseSection.course'
            }
        })
      .finally(() => {
        this.lesson.students.sort((a, b) => a.last_name > b.last_name ? 1 : -1)
        if(this.lesson.evaluated) {
          this.lesson.students = this.lesson.students.map(it => ({
            id: it.id,
            first_name: it.first_name,
            last_name: it.last_name,
            delayed: it.delayed,
            attendance: it.attendance,
            failed_by_delay: it.failed_by_delay,
            note: it.note
          }))
        this.students = this.lesson.students.map(it => ({
            id: it.id,
            first_name: it.first_name,
            last_name: it.last_name,
            delayed: it.delayed,
            attendance: it.attendance,
            failed_by_delay: it.failed_by_delay,
            note: it.note
        }))
        }
      })
        this.date = this.lesson?.date
    }
}
</script>
